<template>
    <Modal v-model="show" title="编辑角色" :width="640" :mask-closable="false">
        <Form ref="roleForm" :model="roleForm" :rules="roleFormRule" label-colon :label-width="90">
            <FormItem label="角色名称" prop="roleName">
                <Input type="text" show-word-limit :maxlength="50" v-model.trim="roleForm.roleName" placeholder="请输入"/>
            </FormItem>
            <FormItem label="描述" prop="description">
                <Input type="textarea" show-word-limit :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" v-model.trim="roleForm.description" placeholder="请输入" />
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="submit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {reqEditRole, reqGetRoleById} from '../../../api/system-api'

export default {
    name: "editRole",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        roleId: {
            type: Number,
        }
    },
    data() {
        return {
            show: this.value,
            loading: false,
            roleForm: {
                roleId: '',
                roleName: null,
                description: null,
            },
            roleFormRule: {
                roleName: [
                    {required: true, message: '请输入角色名称', trigger: 'blur'},
                ]
            }
        }
    },
    methods: {
        //修改
        submit() {
            let that = this;
            that.$refs['roleForm'].validate((valid) => {
                if (valid) {
                    that.loading = true;
                    delete that.roleForm['createTime'];
                    let para = Object.assign({}, this.roleForm)
                    reqEditRole(para).then(res => {
                        that.loading = false;
                        if (res.data.code == 1) {
                            that.$Message.success('编辑成功');
                            // 提交表单数据成功则关闭当前的modal框
                            that.closeModal(false);
                            // 同时调用父页面的刷新页面的方法
                            that.$emit('getRoles');
                        } else {
                            that.$Message.error('编辑失败');
                        }
                    }).catch(()=>{
                        that.loading = false;
                        that.$Message.error('编辑失败');
                    })
                }
            });
        },
        closeModal(val) {
            this.$emit('input', val);
        },
    },
    watch: {
        value(val) {
            this.show = val;
        },
        show(val) {
            //当重新显示增加数据的时候重置整个form表单
            this.show = val;
            if (val) {
                this.$refs['roleForm'].resetFields();
                //通过Id查询角色信息
                reqGetRoleById({roleId: this.roleId}).then(res => {
                    this.roleForm = res.data.data;
                });
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
}
</script>
